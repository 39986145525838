import { useGetAllInvitesQuery, useGetInvitedQuery } from "../apis/threads";
import { ThreadInfo, ThreadWithInvite } from "../types";
import { ThreadDisplayItemHolder } from "../components/ThreadDisplayItem";
import SkeletonThreads from "../components/SkeletonThreads";

export default function InvitedContacts() {
  const {
    data: threads,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetInvitedQuery();
  if (isLoading) {
    return <SkeletonThreads />;
  }
  if (threads?.length === 0) {
    return <div className={"noResults"}>You didn't invite anyone yet</div>;
  }
  return (
    <div className={"areagridcontent"}>
      {threads?.map((d) => {
        return (
          <ThreadDisplayItemHolder {...d}>
            <div>
              {d.invitedUserDisplayName ? (
                <div>Invited {d.invitedUserDisplayName} on</div>
              ) : (
                <div>Sent an invitation link</div>
              )}
              <small className="thread-start">
                {new Date(d.actionDate).toLocaleDateString()}
              </small>
              <small className={"thread-start"}>
                {d.accepted
                  ? "Accepted"
                  : d.declined
                    ? "Declined"
                    : "No response"}
              </small>
            </div>
          </ThreadDisplayItemHolder>
        );
      })}
    </div>
  );
}
