import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ThreadInfo, ThreadInfoDisplay } from "../types";

export interface Threads {
  currentThread: ThreadInfoDisplay | undefined;
  threadToShare: ThreadInfoDisplay | undefined;
  currentUserName: string | undefined;
  signOutFunction: (() => void) | undefined;
}

const initialState: Threads = {
  currentThread: undefined,
  threadToShare: undefined,
  currentUserName: undefined,
  signOutFunction: undefined,
};

export const threadSlice = createSlice({
  name: "threads",
  initialState,
  reducers: {
    setCurrentThread: (state, action: PayloadAction<ThreadInfoDisplay>) => {
      state.currentThread = action.payload;
    },
    removeCurrentThread: (state) => {
      state.currentThread = undefined;
    },
    setThreadToShare: (state, action: PayloadAction<ThreadInfoDisplay>) => {
      state.threadToShare = action.payload;
    },
    removeThreadToShare: (state) => {
      state.threadToShare = undefined;
    },
    setCurrentUser: (state, action: PayloadAction<string>) => {
      state.currentUserName = action.payload;
    },
    setSignOutFunction: (state, action: PayloadAction<() => void>) => {
      state.signOutFunction = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentThread,
  removeCurrentThread,
  setThreadToShare,
  removeThreadToShare,
  setCurrentUser,
  setSignOutFunction,
} = threadSlice.actions;

export default threadSlice.reducer;
