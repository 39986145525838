import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { removeThreadToShare } from "../slices/threadInfo";
import {
  useGetInvitedQuery,
  useInviteUserMutation,
  useLazyGetOneThreadQuery,
} from "../apis/threads";
import { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";

interface Contact {
  userId: string;
  displayName: string;
}

function ContactsAutocomplete({
  contacts,
  onSelectedContact,
}: {
  contacts: Contact[];
  onSelectedContact: (contact: Contact | undefined) => void;
}) {
  const [suggestions, setSuggestions] = useState<Contact[]>([]);
  const [value, setValue] = useState<string>("");

  const getSuggestions = (value: string) => {
    const inputValue = value?.trim()?.toLowerCase();
    if (inputValue.length === 0) {
      return [];
    }
    return contacts.filter(
      ({ displayName }) => displayName.toLowerCase().indexOf(inputValue) !== -1,
    );
  };

  const renderSuggestion = (contact: Contact) => (
    <div>{contact.displayName}</div>
  );

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    onSelectedContact(undefined);
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const getSuggestionValue = (contact: Contact) => contact.displayName;

  const inputProps = {
    placeholder: "Enter your contact name",
    value,
    onChange: (_: any, { newValue }: { newValue: string }) => {
      setValue(newValue);
    },
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={(event: any, { suggestion }) =>
        onSelectedContact(suggestion)
      }
      // @ts-ignore
      inputProps={inputProps}
    />
  );
}

export default function InviteDialog() {
  const threadToShare = useSelector(
    (state: RootState) => state.threadInfo.threadToShare,
  );
  const {
    data: invited,
    isLoading: getInvitationsIsLoading,
    isSuccess: isGetInvitationsSuccess,
    isError: isGetInvitationsError,
    error: getInvitationsError,
  } = useGetInvitedQuery();

  const dispatch = useDispatch();
  const [showContacts, setShowContacts] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(
    undefined,
  );
  const [trigger, result] = useLazyGetOneThreadQuery();

  const [invite, { data: invitedUserId, isLoading, error, reset }] =
    useInviteUserMutation();

  useEffect(() => {
    if (threadToShare?.threadId) {
      trigger(threadToShare.threadId, true);
    }
  }, [trigger, threadToShare]);

  let invitedMap: Record<string, string> = {};
  let normalizedList: Contact[] = [];
  for (const { invitedUser, invitedUserDisplayName } of invited || []) {
    if (!invitedUser || !invitedUserDisplayName) {
      continue;
    }
    const userId = invitedUser;
    if (!invitedMap[userId]) {
      normalizedList = [
        ...normalizedList,
        { userId, displayName: invitedUserDisplayName },
      ];
    }
    invitedMap[userId] = invitedUserDisplayName;
  }
  const successMessage = showContacts
    ? "The invitation is sent to the user"
    : "The invitation link is copied to clipboard";
  // TODO for sending email, the message will be different

  useEffect(() => {
    if (invitedUserId) {
      const inviteUrl = `${process.env.REACT_APP_APPLICATION_ROOT}/#/invite/${invitedUserId}`; //temporary use has access to only that thread , no need to specify it
      navigator.clipboard.writeText(inviteUrl).then((_) => {
        console.log(`${inviteUrl} copied to clipboard`);
        // closeDialog();
      });
    }
  }, [invitedUserId]);

  function closeDialog() {
    reset();
    setShowContacts(false);
    dispatch(removeThreadToShare());
  }

  async function getInviteLink() {
    if (!threadToShare || !result?.data) {
      return;
    }
    try {
      await invite({
        threadId: threadToShare.threadId,
        url: result.data.siteurl,
      });
    } catch (error: any) {
      console.error("Error when sharing the invite link");
    }
  }

  async function inviteContact() {
    try {
      if (!threadToShare || !result?.data) {
        return;
      }
      await invite({
        threadId: threadToShare.threadId,
        url: result.data.siteurl,
        invitedUser: selectedContact?.userId,
      });
    } catch (error: any) {
      console.error("Error when sending the invictaion to the user");
    }
  }

  const Buttons = () => {
    if (showContacts && normalizedList && normalizedList.length > 0) {
      return (
        <SlButton
          slot={"footer"}
          variant={"primary"}
          disabled={!selectedContact}
          loading={isLoading}
          onClick={inviteContact}
        >
          Send
        </SlButton>
      );
    }
    if (!invitedUserId && !error) {
      return (
        <>
          <SlButton
            slot="footer"
            variant="primary"
            onClick={getInviteLink}
            loading={isLoading}
          >
            Copy Link
          </SlButton>
          <SlButton
            slot="footer"
            variant="primary"
            onClick={() => setShowContacts(true)}
            disabled={isLoading}
          >
            Contacts
          </SlButton>
        </>
      );
    }
    return null;
  };
  let errMsg: string | undefined;
  if (error) {
    if ("status" in error) {
      errMsg = (error as any).data.message;
    } else {
      errMsg = error.message;
    }
  }
  return (
    <SlDialog
      open={!!threadToShare}
      label={"Share the discussion"}
      onSlHide={closeDialog}
    >
      Copy the link or share with your contacts
      {showContacts && normalizedList && normalizedList.length > 0 && (
        <ContactsAutocomplete
          contacts={normalizedList}
          onSelectedContact={setSelectedContact}
        />
      )}
      <Buttons />
      <SlButton slot="footer" onClick={closeDialog} disabled={isLoading}>
        Close
      </SlButton>
      <div className={"alertMessages"}>
        <SlAlert variant={"success"} open={!!invitedUserId}>
          <SlIcon slot={"icon"} name={"check2-circle"} />
          <strong>{successMessage}</strong>
        </SlAlert>
        <SlAlert variant={"danger"} open={!!error}>
          <SlIcon slot="icon" name="exclamation-octagon" />
          <strong>{errMsg}</strong>
        </SlAlert>
        <SlAlert
          variant={"warning"}
          open={
            showContacts && (!normalizedList || normalizedList.length === 0)
          }
        >
          <SlIcon slot="icon" name="exclamation-triangle" />
          <strong>You don't have any contacts yet</strong>
          <br />
          Send the invitation link to someone you know
        </SlAlert>
      </div>
    </SlDialog>
  );
}
