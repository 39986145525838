import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlCard from "@shoelace-style/shoelace/dist/react/card";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";
import {
  threadInfoApi,
  useChangeSubscriptionMutation,
  useGetSubscriptionInfoQuery,
  useStartSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from "../apis/threads";
import { useEffect, useState } from "react";
import { SubscriptionInfo } from "../types";
import { useNavigate, useSearchParams } from "react-router-dom";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import PasswordChanger from "../components/PasswordChanger";

const subscriptionPriceIds: Record<"tier1" | "tier2", string> = {
  tier1: process.env.REACT_APP_PRODUCT_TIER1!,
  tier2: process.env.REACT_APP_PRODUCT_TIER2!,
};

const css = `
  .card-overview {
    max-width: 300px;
  }

  .card-overview small {
    color: var(--sl-color-neutral-500);
  }

  .card-overview [slot="footer"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

interface TierSubscription {
  tier: "tier1" | "tier2";
  planLabel: string;
  planDetails: string[];
}

function SubscripitonInfoCard({
  planDetails,
  planLabel,
  loading,
  startSubscriptionEvent,
  buttonLabel,
}: {
  planDetails: string[];
  planLabel: string;
  loading: boolean;
  startSubscriptionEvent: () => void;
  buttonLabel?: string;
}) {
  return (
    <>
      <SlCard className="card-overview">
        <strong>{planLabel}</strong>
        {planDetails.map((line: string, key: number) => {
          return (
            <div key={key}>
              <br />
              {line}
            </div>
          );
        })}
        <div slot="footer">
          <SlButton
            variant="primary"
            pill
            onClick={startSubscriptionEvent}
            loading={loading}
          >
            {buttonLabel ?? "Subscribe"}
          </SlButton>
        </div>
      </SlCard>

      <style>{css}</style>
    </>
  );
}

const SubscriptionCard = ({
  tier,
  planLabel,
  planDetails,
}: TierSubscription) => {
  const [startSubscription, { data, isLoading, isSuccess, error }] =
    useStartSubscriptionMutation();

  const startSubscriptionEvent = () => {
    startSubscription(subscriptionPriceIds[tier]);
  };

  useEffect(() => {
    console.log("Got the location to redirect from stripe");
    console.log(data);
    if (data?.location) {
      window.location.replace(data.location);
    }
  }, [data]);

  return (
    <SubscripitonInfoCard
      planDetails={planDetails}
      planLabel={planLabel}
      loading={isLoading}
      startSubscriptionEvent={startSubscriptionEvent}
    />
  );
};

function UpgradeSubscriptionCard({
  tier,
  planLabel,
  planDetails,
}: TierSubscription) {
  const [upgradeSubscription, { isLoading, isSuccess }] =
    useUpgradeSubscriptionMutation();

  const navigate = useNavigate();

  const startSubscriptionEvent = () => {
    upgradeSubscription(subscriptionPriceIds[tier]);
  };

  if (isSuccess) {
    return (
      <div>
        <SlAlert>Subscription successfully updated</SlAlert>
        <SlButton onClick={() => navigate("/")}>Close</SlButton>
      </div>
    );
  }
  return (
    <SubscripitonInfoCard
      planDetails={planDetails}
      planLabel={planLabel}
      loading={isLoading}
      startSubscriptionEvent={startSubscriptionEvent}
      buttonLabel={"Upgrade"}
    />
  );
}

function getTierDisplayName(tier: string): string {
  switch (tier) {
    case "freeTier":
      return "Free Plan";
    case "tier1":
      return "Private Plan";
    case "tier2":
      return "Sharing Plan";
    default:
      return "Unknow plan";
  }
}

function UserSubscriptionDisplay({
  userInfo,
  signOut,
}: {
  userInfo: SubscriptionInfo;
  signOut: () => void;
}) {
  function isExternalUser(userName: string) {
    return userName?.startsWith("google"); // only external provider so far
  }

  const [changePasswordDisplayed, setChangePasswordDisplayed] = useState(false);
  return (
    <div>
      <div className={"userName"}>User: {userInfo.displayName}</div>
      <div className={"userEmail"}>Email: {userInfo.email}</div>
      <div className={"userPlan"}>
        Plan: {getTierDisplayName(userInfo.usertype)}
      </div>
      {userInfo.tierExpiresOn && (
        <div className={"planValidUntil"}>
          Valid until: {new Date(userInfo.tierExpiresOn!).toLocaleDateString()}
        </div>
      )}
      <SlButton onClick={signOut}>Sign Out</SlButton>
      {!isExternalUser(userInfo.user) ? (
        <SlButton
          onClick={() => setChangePasswordDisplayed(true)}
          variant={"text"}
        >
          Change Password
        </SlButton>
      ) : null}
      <PasswordChanger
        onClose={() => setChangePasswordDisplayed(false)}
        visible={changePasswordDisplayed}
      />
    </div>
  );
}

function FreeTierSubscriptionInfo() {
  return (
    <div className={"subscriptionSection"}>
      <div className={"subscriptionTitle"}>
        You are currently using the free plan. Switch to paid plan to get the
        most of Mark and Note
      </div>
      <div className={"subscriptionCards"}>
        <SubscriptionCard
          tier={"tier1"}
          planLabel={"Private plan"}
          planDetails={["Private highlights and comments"]}
        />
        <SubscriptionCard
          tier={"tier2"}
          planLabel={"Sharing plan"}
          planDetails={[
            "Private highlights and comments",
            "Invite others",
            "Share and discuss",
          ]}
        />
      </div>
    </div>
  );
}

function TrialSubscriptionInfo() {
  return (
    <div className={"subscriptionSection"}>
      <div className={"subscriptionTitle"}>
        Your trial will expire soon. Subscribe now.
      </div>
      <div className={"subscriptionCards"}>
        <SubscriptionCard
          tier={"tier1"}
          planLabel={"Private plan"}
          planDetails={["Private highlights and comments"]}
        />
        <SubscriptionCard
          tier={"tier2"}
          planLabel={"Sharing plan"}
          planDetails={[
            "Private highlights and comments",
            "Invite others",
            "Share and discuss",
          ]}
        />
      </div>
    </div>
  );
}

function ChangeSubscriptionInfo() {
  const [changeSubscription, { data, isLoading, isSuccess, error }] =
    useChangeSubscriptionMutation();

  useEffect(() => {
    console.log("Got the location to redirect from stripe");
    console.log(data);
    if (data?.location) {
      window.location.replace(data.location);
    }
  }, [data]);
  return (
    <div className={"subscriptionSection"}>
      <div className={"subscriptionTitleThin"}>
        Change the payment method or cancel the subscription
      </div>
      <SlButton loading={isLoading} onClick={() => changeSubscription()}>
        Change
      </SlButton>
    </div>
  );
}

function UpgradeSubscriptionInfo({ userInfo }: { userInfo: SubscriptionInfo }) {
  // TODO this is valid until I introduce the tier 3
  return (
    <div className={"subscriptionSection"}>
      <div className={"subscriptionTitle"}>
        You are currently subscribed to Private plan. Upgrade to get more of
        Mark and Note
      </div>
      <UpgradeSubscriptionCard
        tier={"tier2"}
        planLabel={"Sharing plan"}
        planDetails={[
          "Private highlights and comments",
          "Invite others",
          "Share and discuss",
        ]}
      />
    </div>
  );
}

export default function SubscriptionCheckout() {
  const dispatch = useDispatch();
  const _signOut = useSelector((state: RootState) => {
    return state.threadInfo.signOutFunction;
  });

  function signOut() {
    if (!_signOut) {
      return;
    }
    // this is for Google auth, sign out goes out of the page
    localStorage.setItem("cognitoLoggedOut", "true");
    console.log("Signing out");
    _signOut!();
    localStorage.clear();
    window.location.href = "/";
  }

  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  if (searchParams.get("logout") === "true") {
    console.log("Logging out from extension");
    signOut();
  }
  const { data: subscriptionInfo, isLoading: subsriptionInfoLoading } =
    useGetSubscriptionInfoQuery();
  if (subsriptionInfoLoading) {
    return (
      <div>
        <SlSpinner />
      </div>
    );
  }

  if (!subscriptionInfo) {
    dispatch(threadInfoApi.util?.invalidateTags(["SubscriptionInfo"]));
    return (
      <div>
        <SlSpinner />
      </div>
    );
  }

  return (
    <div>
      <div>
        <SlButton variant="text" size="large" onClick={() => navigate(-1)}>
          <SlIcon slot="prefix" name="arrow-left"></SlIcon>
          Go Back
        </SlButton>
      </div>
      <UserSubscriptionDisplay userInfo={subscriptionInfo} signOut={signOut} />
      <SlDivider />
      {subscriptionInfo.usertype === "freeTier" ? (
        <FreeTierSubscriptionInfo />
      ) : (
        <div>
          {subscriptionInfo.usertype === "tier1" ? (
            <>
              <UpgradeSubscriptionInfo userInfo={subscriptionInfo} />
              <SlDivider />
            </>
          ) : null}
          {subscriptionInfo.lastPayedUserStatus ? (
            <ChangeSubscriptionInfo />
          ) : (
            <TrialSubscriptionInfo />
          )}
        </div>
      )}
    </div>
  );
}
